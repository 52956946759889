import React, { useEffect, useState } from 'react'
import './MintSection.scss';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import { toast } from 'react-toastify';
import loading from '../../assets/images/loading.svg'

// import Web3EthContract from "web3-eth-contract";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { ethers } from "ethers";

function MintSection() {

  const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [paused, setPaused] = useState('')
  const [cost, setCost] = useState('')
  const [maxPerWallet, setMaxPerWallet] = useState('');
  const [limit, setLimit] = useState('')
  const [totalSupply, setTotalSupply] = useState('')
  const [refresh, setRefresh] = useState(0)

  const [balance, setBalance] = useState('')
  const [addressWhitelisted, setAddressWhitelisted] = useState(true)
  const [presaleActive, setPresaleActive] = useState('')


  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = async () => {
    try {
      if (paused === true) {
        toast.error('Mint coming soon')
        return;
      }
      if (claimingNft || cost === '') return;

      let cost2 = cost;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintAmount);
      let totalGasLimit = String(gasLimit * mintAmount);

      toast.success(`Minting your ${mintAmount > 1 ? 'OTW NFTs' : 'OTW NFT'}...`);
      setClaimingNft(true);
      const options = {
        gasLimit: String(totalGasLimit),
        // to: CONFIG.CONTRACT_ADDRESS,
        // from: blockchain.account,
        value: totalCostWei,
      }
      await blockchain.smartContract
        .mint(mintAmount, options)
        toast.success(() =>
        <span>
          OTW Mint Successful! Visit <a href="https://opensea.io/collection/out-this-world-official/">opensea.io</a> to view it.
        </span>
      );
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
      setRefresh(refresh + 1)
      setMintAmount(1)

      

    }
    catch (err) {
      console.log(err)
      toast.error("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    }

  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();

  }, []);

  useEffect(() => {
    getData();

  }, [blockchain.account]);

  const mint = () => {
    claimNFTs();
    getData();
  }

  const connectWallet = () => {
    // if(paused===''&&ethereum===null)return;
    dispatch(connect());
    getData();
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > limit) {
      newMintAmount = limit;
    }
    if (limit === '') {
      newMintAmount = 1
    }
    if (limit === 0) {
      newMintAmount = 1

    }
    setMintAmount(newMintAmount);
  };



  const setAmountInput = (amount) => {
    amount = Number(amount)
    if (isNaN(amount)) return;
    if (amount > limit) {
      setMintAmount(limit)
    }
    else {
      setMintAmount(amount)
    }
  }

  const initConnect = async () => {
    // const { ethereum } = window;
    let ethereum = window?.ethereum?.providers !== undefined ? window?.ethereum?.providers?.find((x) => x?.isMetaMask) : window.ethereum
    // let walletProvider = window.ethereum.providers.find((x) => x.isCoinbaseWallet) // isCoinbaseWallet is part of the injected provider



    const address = CONFIG.CONTRACT_ADDRESS
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();

    let web3 = new ethers.providers.Web3Provider(ethereum);

    const contract = new ethers.Contract(CONFIG.CONTRACT_ADDRESS, abi, web3);

    if (ethereum) {
      ethereum.on("accountsChanged", (accounts) => {
        setRefresh(refresh + 1)
      });
      ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      try {
        const cost = await contract.cost();


        const paused = await contract.paused();
        const totalSupply = await contract.totalSupply();
        const maxPerWallet = await contract.maxMintAmountPerPublicAccount();

        setCost(cost)
        setTotalSupply(Number(totalSupply))
        setMaxPerWallet(Number(maxPerWallet))
        // console.log(maxPerWallet)
        setPaused(paused)

      }
      catch (err) {
        console.log(err)
      }

      // setEthereum(ethereum)
    }
    else {
      // toast.error('Install metamask!')
    }

  }

  useEffect(() => {
    if (CONFIG.CONTRACT_ADDRESS === '') return;

    initConnect()

  }, [refresh, CONFIG])

  const getLimit = async () => {
    try {
      const balance = await blockchain.smartContract.balanceOf(blockchain.account)

      const nftsOwned = Number(balance)
      let limit = Number(maxPerWallet) - nftsOwned;
      if (limit <= 0) {
        setLimit(0)

      }
      else {
        setLimit(limit)
      }
    }
    catch (err) {
      console.log(err)
    }

    // blockchain.contract.balanceOf(blockchain.account).then((result)=>{
    //   console.log(maxPerWallet,result)
    //   setBalance(Number(result));
    //   let limit=Number(maxPerWallet)-result;
    //   if(limit<=0){
    //     setLimit(0)

    //   }
    //   else{
    //     setLimit(limit)

    //   }

    // })
  }
  useEffect(() => {
    if (blockchain.account === null || maxPerWallet === '') return;

    getLimit()
  }, [refresh, blockchain.account, maxPerWallet])

  // useEffect(()=>{
  //   connectWallet()
  // },[])

  // console.log(addressWhitelisted)
  // console.log(presaleActive)
  console.log(blockchain.account)
  console.log(limit)
  return (
    <div className='MintSection'>


      {blockchain.account &&
        <div className='connected-address text-white'>
          <span className='text-white font-s weight-bold'>Connected As</span>
          <span className='text-white font-xs'> {truncate(blockchain.account, 15)}</span>
        </div>}
      <div className='mint-section-inner'>
        <span className='font-xls family-raleway weight-extra-bold text-white'>NFT Now</span>
        <p className='font-xs family-raleway text-white'>Price Per NFT : {cost && (cost / 1000000000000000000)}ETH per NFT + gas fee</p>
        <p className='font-xs family-raleway text-white'>Limit: {maxPerWallet} per wallet</p>
        <p className='font-xs family-raleway text-white'>{totalSupply}/10000</p>

        <div className='mint-section-inner-bottom '>
        {
            (blockchain.account !== null) &&
          <div className='mint-input-div'>
            <button onClick={() => { decrementMintAmount() }} className='amount-button font-xl family-raleway text-white'>
              -
            </button>
            <input className='font-l family-raleway text-white' value={mintAmount} onChange={(e) => { setAmountInput(e.target.value) }} />
            <button onClick={() => { incrementMintAmount() }} className='amount-button font-xl family-raleway text-white'>
              +
            </button>
          </div>
        }
          {
            (blockchain.account == null) &&
            (<button onClick={() => { connectWallet() }} className='font-m mint-button family-raleway text-white weight-bold'>
              Connect Wallet
            </button>)
          }
          {/* <CrossmintPayButton
              clientId="4ff8564e-b786-4c38-8447-8bc4c1588bd0"
              mintConfig={{"type":"erc-721","totalPrice":"0.11"}}
              style={{ fontFamily: 'Lato', borderRadius: 10 }}
              className='mint-section-connect-btn'
              theme='light'
          /> */}
          <CrossmintPayButton
            collectionId="d0d1930a-2ccf-4d9b-81e6-10f7fd425330"
            projectId="c58b1f03-610e-4e0e-83a9-18e034f56321"
            mintConfig={{ "totalPrice": "0.0001" }}
            environment="staging"
            style={{ fontFamily: 'Raleway',}}
            className='mint-section-crossmint-btn font-xs'
          />
          {
            blockchain.account !== null && addressWhitelisted === true && limit > 0 ?
              (

                <button onClick={() => claimNFTs()} className='font-m mint-button family-raleway text-white weight-bold'>
                  {claimingNft === true ? (<img src={loading} />) : 'MINT NOW'}
                </button>

              )
              : addressWhitelisted === false ?
                <div className='text-red font-m family-raleway weight-bold'>
                  {blockchain.account !== null &&
                    <div style={{ textAlign: 'center', paddingTop: '25px' }}>
                      <div>
                        Not Whitelisted!
                      </div>
                    </div>
                  }



                </div>
                : limit === 0 ?
                  <div className='text-red font-s family-raleway weight-bold'>
                    {blockchain.account !== null &&
                      <div style={{ textAlign: 'center', paddingTop: '25px' }}>
                        <div>
                          Mint Limit Exceeded
                        </div>
                      </div>

                    }
                  </div>
                  : blockchain.account !== null ?
                    <button className='font-xs mint-button family-raleway'>
                      <img src={loading} />
                    </button> : null
          }
        </div>
      </div>
    </div>
  )
}

export default MintSection
